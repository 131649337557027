.carousel-inner {
    text-align: center;
}

.carousel-indicators{
    top: 512px
}

.img-carousel {
    margin-bottom: 16px;
}

.form-control-media input{
    margin-right: 8px;
}

.quantity-social-medias {
    font-size: 14px;
    color: #979797;
    margin-bottom: 0;
    margin-left: 8px;
}

.modal-social-media .isCompany {
    vertical-align: middle;
    margin: unset !important;
}

.modal-social-media .react-toggle {
    vertical-align: middle;
    margin: auto;
}

.face-page-control {
    text-align: left;
}

/*.face-page-control input {
    margin-left: 16px;
}*/