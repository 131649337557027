#page-auth {
    display: flex;
    align-items: stretch;
    height: 100vh;

    aside{
        //flex: 7;
        flex: 12;
        //background: #835afd;
        color: #FFF;
        display: flex;
        //flex-direction: column;
        //justify-content: center;
        //padding: 120px 80px;

        img{
            //max-width: 320px;
            //max-width: 730px;
            width: 100%;
            object-fit: cover;
            height: 100vh;
        }

        strong {
            font: 700 36px 'Poppins', sans-serif;
            line-height: 42px;
            margin-top: 16px;
        }

        p{
            font-size: 24px;
            line-height: 32px;
            margin-top: 16px;
            color: #f8f8f8;
        }

        @media only screen and (max-width: 768px){
            display: none;
        }
    }

    main{
        flex: 8;
        padding: 0 48px;
        display: flex;
        //align-items: center;
        //justify-content: center;

        @media only screen and (max-width: 768px){
            padding: 0 28px;
            margin: auto 0 auto 0;
            justify-content: center;
        }
    }

    .main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        //max-width: 320px;
        max-width: 420px;
        //align-items: stretch;
        margin-top: auto;
        margin-bottom: auto;
        //text-align: center;

        @media only screen and (max-width: 768px){
            margin-top: 0;
        }

        > h5 {
            margin-left: 20px;
            margin-bottom: 12px;

            @media only screen and (max-width: 768px){
                margin-left: 0;
            }
        }

        > img {
            //align-self: center;
            align-self: flex-start;
            width: 220px;
            //margin-top: 42px;
            margin-bottom: 96px;

            @media only screen and (max-width: 768px){
                margin: 0px auto 96px auto;
            }

        }

        form {

            @media only screen and (max-width: 768px){
                padding: 10px 0px;
            }

            input{
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                background: #FFF;
                border: 1px solid #a8a8b3
            }

            button{
                margin-top: 16px;
                margin-bottom: 96px;
                width: 50% !important;

                @media only screen and (max-width: 768px){
                    margin-right: 16px !important;
                }
            }

            button, input {
                width: 100%;
            }
        }
    }

    .auth-google{
        margin-top: 64px;
        height: 50px;
        border-radius: 8px;
        font-weight: 500;
        background: #ea4335;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;

        transition: filter 0.2s;

        img{
            margin-right: 8px;
        }

        &:hover{
            filter: brightness(0.9);
        }
    }

    .separator{
        font-size: 14px;
        color: #a8a8b3;
        margin: 24px 0;
        display: flex;
        align-items: center;

        &::before{
            content: '';
            flex: 1;
            height: 1px;
            background: #a8a8b3;
            margin-right: 16px;
        }

        &::after{
            content: '';
            flex: 1;
            height: 1px;
            background: #a8a8b3;
            margin-left: 16px;
        }
    }

    .input-email {
        margin-bottom: 10px;
    }
}