html, body{
  padding: 0;
  margin: 0;
}

p{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.line1 > a, .line2 > a{
  text-decoration: none;
  padding: 10px;
}

.digital-card{
  display: block;
  position: fixed;
  width: 100%;
  max-width: 320px;
  height: 100%;
  min-height: 550px;
  max-height: 550px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  perspective: 1500px;
}

.flipper{
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform .5s;
  display: block;

}

.front, .back {

  .button-arrow {
    //background: none;
    //color: inherit;
    //border: none;
    //padding: 0;
    //font: inherit;
    //cursor: pointer;
    //outline: inherit;
    //position: fixed;
    position: absolute;
    //right: 15px;
    right: 0px;
    top: 0px;
    //top: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    //padding: 25px;
    padding: 25px 25px 25px 30px;
  }
}

.front, .back{
  padding-left: 40px;
  padding-right: 40px; 
  height: 100%;
}

.content-front, .content-back{
  position: absolute;
  background-color: #c4c4c4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 30px;
  box-shadow: 7px 15px 20px;
  justify-content: center;
}

.flipper.is-flipped{
  transform: translateX(-100%) rotateY(-180deg);
}

.flipper .content{
  position: absolute;
  width: 100%;
  height: 100%;
  color: #979797;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  backface-visibility: hidden;
  justify-items: center;
}

@keyframes buttonBouncyRight{
  0% {
    right: 30px;
  }
  40% {
    right: 30px;
  }
  43% {
    right: 20px;
  }
  46% {
    right: 30px;
  }
  48% {
    right: 20px;
  }
  50% {
    right: 30px;
  }
  100% {
    right: 30px;
  }
}

@keyframes buttonBouncyLeft{
  0% {
    right: 30px;
  }
  40% {
    right: 30px;
  }
  43% {
    right: 40px;
  }
  46% {
    right: 30px;
  }
  48% {
    right: 40px;
  }
  50% {
    right: 30px;
  }
  100% {
    right: 30px;
  }
}

.arrow-right{
  transition: all 0.3s;
  animation: buttonBouncyRight 5s infinite linear;
}

.arrow-left{
  transition: all 0.3s;
  animation: buttonBouncyLeft 5s infinite linear;
}

.arrow-right, .arrow-left{
  //position: fixed;
  position: absolute;
  right: 30px;
  top: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.content-front{
  //background:#1a27c1;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.content-back{
  transform: rotateY(180deg);
}

.company-logo{
  margin-top: 24px;
  max-width: 300px;
  max-height: 200px;
}

.profile_picture{
  //margin-top: 20px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 70px;
}

.name{
  margin-top: 16px;
  font-size: 18px;
}

.occupation{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

.occupation-complement{
  margin-top: 8px;
  font-size: 12px;
  font-weight: 300;
}

/*Social Media Section*/
.social-media, .face-social-media{
  //position: fixed;
  position: absolute;
  right: 20px;
  left: 20px;
  top: 364px;
  padding-right: 25px;
  padding-left: 25px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.social-media a {
  width: 60px;
  text-decoration: none;
}

.phone > p, .whatsapp > p, .email > p, .instagram > p, .location > p, .website > p, .linkedin > p, .facebook > p{
  font-size: 10px; 
  color: #c4c4c4;
  transition: ease .2s;
}

/*.phone:hover > p, .whatsapp:hover > p, .email:hover > p, .instagram:hover > p, .location:hover > p, .website:hover > p, .linkedin:hover > p, .facebook:hover > p{
  color: #FFFFFF;
}*/

.phone > svg, .whatsapp > svg, .email > svg, .instagram > svg, .location > svg, .website > svg, .linkedin > svg, .facebook > svg{

  margin: 10px;
  padding: 8px;
  width: 40px;
  height: 40px;
  //background-color: #c4c4c4ab;
  border-radius: 15px;
  transition: ease .2s;
}

/*.phone:hover > svg, .whatsapp:hover > svg, .email:hover > svg, .instagram:hover > svg, .location:hover > svg, .website:hover > svg, .linkedin:hover > svg, .facebook:hover > svg{
  background: #ffffff ;
}*/

.qr-code {
  margin-top: 10px;
  overflow: hidden !important;
}

.div-qrcode {
    height: 120px;
    width: 120px;
    align-items: center;
    justify-content: center;
    margin: 32px auto 12px auto;  
    border-radius: 20px;
    background-color: #ffffff;
}

.company-name{
  margin-top: 5px;
  font-size: 14px;
}

.company-address{
  margin-top: 10px;
  font-size: 12px;
}

.link-website, .copy, .add-contact, .share{
  text-decoration: none;
}

.copy, .add-contact, .share{
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-contact, .share{
  margin-top: 8px;
}

.link-website{

  display: grid;
  justify-content: center;
  
  p {
    padding: 10px;
    font-size: 12px;
  }
}

.actions {
  position: fixed;
  bottom: 80px;
}

.actions p {
  font-size: 14px;
}

.actions img {
  margin-right: 5px;
}

.copy, .add-contact, .share{
  padding: 5px;
  cursor: pointer;
}

.actions a:nth-child(1), a:nth-child(2){
  margin-bottom: 5px;
}

.footer-back {
  font-size: 12px;
  position: fixed;
  bottom: 8px;
  margin: 0 auto 0 auto;
  right: 0;
  left: 0;
}

.modal-share-social-medias {
  text-align: center;
}

.share-facebook-btn, .share-whatsapp-btn, .share-telegram-btn {
  margin-right: 16px; 
} 