.dropzone {
    width: 100%;
    background: #e1faec;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 0;
    outline: 0;
    padding: 20px 0;
}
  
.dropzone img {
    width: 100%;
    object-fit: cover;
    max-width: 250px;
    max-height: 300px;
    margin-bottom: 15px;
}

.dropzone p {
    width: 80%;
    margin-bottom: 0;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed #79ab7f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
}

.dropzone p svg {
    margin-bottom: 8px;
}

.div-file-img{
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-items: center;
}

.file-img {
    width: 60px;
}