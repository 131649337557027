.edit-button{
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  color: #737380;
  padding: 14px;
  transition: ease 0.5s;

  &:hover{
    svg {
      fill: #45bfca;
    }
  }
}

.company{
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.16);
  padding: 24px;
  //display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  & + .company{
    margin-top: 8px;
  }

  .header-company-client-card{
    display: flex;
    justify-content: center;
  }

  .header-company{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .company-id-list p{
    margin-top: 8px;
    font-size: 12px;
    color: #979797;
  }

  p{
    color: #29292e;
    margin-bottom: 0;
  }

  .icons {

    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 4px;

    .edit-button, .delete-button, .cards, .view-button, .copy-card-link {
        border: 0;
        background: transparent;
        cursor: pointer;
        background-color: #fefefe;
        display: flex;
        align-items: flex-end;
        color: #737380;
        padding: 10px;
        transition: ease 0.5s;
    }

    .edit-button:hover, .cards:hover, .view-button:hover{

      svg {
        fill: #45bfca;
      }
    }

    .delete-button:hover {
      svg {
        fill: #ee2525;
      }
    }

    .copy-card-link:hover path {
      stroke: #45bfca;
    }
  }
}