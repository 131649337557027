#page-dashboard {
    header{
        padding: 24px;
        border-bottom: 1px solid #e2e2e2;

        .content {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            > img {
                max-height: 72px;
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }

        .content-client-dashboard {
            display: flex;
            justify-content: space-between;
        }

        .content-left {
            display: flex;
            justify-content: flex-start;
            gap: 16px;
            align-items: center;

            @media only screen and (max-width: 768px){
                display: grid;
            }
    
            > img {
                max-height: 72px;
                margin-top: 12px;
                margin-bottom: 12px;
                max-width: 180px;
                width: 180px
            }
        }

        .content-right {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            align-items: center;

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-menu {
                min-width: 85px;
            }

            .dropdown #dropdown-basic{
                background-color: transparent;
                border: none
            } 
        }

        .button-logout{
            background-color: #ee2525;
            border-radius: 9999px;
            padding: 8px 16px;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            border: none;
        }
    }

    main {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 30px;

        /*.button-change-password{
            background-color: #3ab621;
            margin: auto 12px;
            width: 80px;
            max-height: 50px;
            border-radius: 30px;
            padding: 8px 16px;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            border: none;
        }*/

        #email-change-password{
            width: 250px;
            height: 50px;
            border-radius: 8px;
            padding: 0 16px;
            margin-top: 8px;
            background: #FFF;
            border: 1px solid #a8a8b3
        }

        .info-company {
            border-radius: 8px;
            padding: 24px;
            background: #e1e1e1e1;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.24);

            p {
                margin-top: 16px;
            }
        }

        .path-site{
            font-size: 14px;
            margin-top: 8px;
            color: #999999;
        }

        .title{
            margin: 32px 0 24px;
            display: flex;
            align-items: center;

            h1{
                font-family: 'Poppins' , sans-serif;
                font-size: 24px;
                color: #29292e;
            }

            span {
                margin-left: 16px;
                background-color: #876cb0;
                border-radius: 9999px;
                padding: 8px 16px;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .input-filter{
            width: 220px;
            height: 50px;
            border-radius: 8px;
            padding: 0 16px;
            margin-top: 8px;
            background: #FFF;
            border: 1px solid #a8a8b3
        }

        .separator {
            font-size: 14px;
            color: #a8a8b3;
            margin: 36px auto 12px auto;
            display: flex;
            border-radius: 30px;
            align-items: center;
            content: '';
            flex: 1;
            height: 2px;
            width: 40%;
            background: #a8a8b3;
        }

        .company-list{
            margin-top: 32px;
            max-width: 500px;
        }
    }
    
}