* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background: #f8f8f8;
    color: #29292e;
}

body, input, button, textarea {
    font: 400 16px 'Roboto', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-ms-reveal {
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 3px currentColor;
}