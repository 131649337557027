.content-loading {
  height: 100vh;
  display: flex;
}

.img-loading {
  margin: auto;
  width: 300px;
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}