.modal{
    padding-left: 0 !important;
}

#rfs-btn{
    margin-top: 10px;
}

.plus-icon {
    margin-left: 16px;
    background-color: #45bfca;
    border-radius: 9999px;
    padding: 8px 16px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border: none;
}

.button-entre-contato, #btn-add-contact{
    //margin-top: 64px;
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    background: #835afd;
    color: #FFF;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    transition: filter 0.2s;

    &:not(:disabled):hover{
        filter: brightness(0.9);
    }

    &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
    }
}


.form-control-media {

    input {
        width: 85%;
    }
}

form {
    padding: 10px 20px;

    .confirm-new-email{
        display: block;
    }

    input{
        width: 100%;
        height: 50px;
        border-radius: 8px;
        padding: 0 16px;
        margin-top: 8px;
        margin-bottom: 4px;
        background: #FFF;
        border: 1px solid #a8a8b3
    }

    button{
        margin-top: 30px;
        height: 50px;
        border-radius: 8px;
        font-weight: 500;
        background: #835afd;
        color: #FFF;
        padding: 0 32px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;
        transition: filter 0.2s;

        &:not(:disabled):hover{
            filter: brightness(0.9);
        }

        &:disabled{
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    label {
        display: flex;
        margin-top: 16px;
        font-weight: bold;
        align-items: center;
    }
  
    .label-admin {
        margin-bottom: 16px;
        margin-right: 8px;
    }

    .div-admin {
        margin-top: 16px;
        display: flex;
        align-items: center;
    }

    select {
        width: 80%;
        height: 50px;
        border-radius: 8px;
        padding: 0 16px;
        margin-top: 8px;
        background: #FFF;
        border: 1px solid #a8a8b3
    }

    span {
        margin-top: 8px;
        font-size: 14px;
        color: red;
        margin-left: 8px;
        margin-block: auto;
    }
}

.form-color-card {
    text-align: initial !important;

    input {
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
    }
}

.button-edit-card{
    display: grid;
    justify-content: center;
    margin-bottom: 32px;
}

.button-edit-card button {
    margin-top: 32px;
    height: 50px;
    border-radius: 8px;
    font-weight: 500;
    background: #835afd;
    color: #FFF;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    transition: filter 0.2s;

    &:not(:disabled):hover{
        filter: brightness(0.9);
    }

    &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
    }
}


